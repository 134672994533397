import {I18n} from '@aws-amplify/core';

import {formatTimestamp} from '../../../utils/TableTimestamps';
import {NavLink} from 'react-router-dom';
import {Registration} from '../types';

export const columns = [
    {
        title: I18n.get('Product Code'),
        width: '1fr',
        render: (item: Registration) => <NavLink to={`/registrations/${item.id}`}>{item.productCode}</NavLink>,
    },
    {
        title: I18n.get('Customer Identifier'),
        width: '1fr',
        render: (item: Registration) => item.customerIdentifier,
    },
    {
        title: I18n.get('Registration AWS Account ID'),
        width: '1fr',
        render: (item: Registration) => item.customerAWSAccountId,
    },
    {
        title: I18n.get('Created'),
        width: '1fr',
        render: (item: Registration) => formatTimestamp(item.created as string),
    },
];
