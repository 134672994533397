export const createProduct = `
    mutation createProduct($input: CreateProductInput!) {
        createProduct(input:$input) {
            id
            productTitle
            shortDescription
            productId
            productArn
            productCode
            status
            meteringServiceSnsTopicArn
            created
            updated
    }
}`;

export const updateProduct = `
    mutation updateProduct($input: UpdateProductInput!) {
        updateProduct(input:$input){
            id
            productTitle
            shortDescription
            productId
            productArn
            productCode
            status
            meteringServiceSnsTopicArn
            created
            updated
        }
}`;

export const deleteProduct = `
    mutation deleteProduct($input: DeleteProductInput!) {
        deleteProduct(input:$input) 
}`;
