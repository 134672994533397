import {useAccessControl} from "./useAccessControl.ts";
import {useAuth} from "./useAuth.ts";

export const useAllowedItems = () => {

    const {role} = useAuth();
    const {evaluateAction} = useAccessControl(role as string)

    const filterAllowed = (items: { rbac?: string }[]) => {

        return items.filter(item => {

            // if no rbac is specified allow it
            if (typeof item.rbac === 'undefined') {
                return true;
            } else {
                // if rbac property is specified - evaluate it and return accordingly
                if (evaluateAction(item.rbac)) {
                    return true;
                }
                return false;

            }
        });

    };

    return {
        filterAllowed,
    };

};
