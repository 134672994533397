import {
    getOffer,
    listOffers
} from './queries';
import {
    createOffer,
    deleteOffer,
    updateOffer,
} from './mutations';

import {GraphQLQuery,} from '@aws-amplify/api';
import {Offer} from "../types.ts";
import {V6Client} from "@aws-amplify/api-graphql";
import {generateClient} from "aws-amplify/api";
import {showErrorNotification} from "../../../components/ServiceNotifications.tsx";


export type ListOffers = {
    listOffers: { items: Offer[] }
}

export type GetOffer = {
    getOffer: Offer
}

export type CreateOffer = {
    createOffer: Offer
}

export type UpdateOffer = {
    updateOffer: Offer
}

export type DeleteOffer = {
    deleteOffer: string
}

let client: V6Client
try {
    client = generateClient();
} catch (e: any) {
    showErrorNotification(e)
}


export const fetchRecords = async (filter: object) => {
    try {
        const response = await client.graphql<GraphQLQuery<ListOffers>>({
                                                                            query: listOffers,
                                                                            variables: {filter: filter},
                                                                        });
        return response.data?.listOffers.items;
    } catch (e) {
        console.trace(e);
    }
};

export const fetch = async (id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<GetOffer>>({
                                                                          query: getOffer,
                                                                          variables: {id: id},
                                                                      });
        return response.data?.getOffer;
    } catch (e) {
        console.trace(e);
    }
};

export const add = async (payload: object) => {

    try {
        const response = await client.graphql<GraphQLQuery<CreateOffer>>({
                                                                             query: createOffer,
                                                                             variables: {input: payload},
                                                                         });
        console.debug('in the service response', response);

        return response.data?.createOffer;
    } catch (e) {
        console.trace(e);
    }
};

export const update = async (id: string, record: Offer) => {

    console.debug('IN UPDATE', id, record);

    delete record.created;
    delete record.updated;

    try {
        const response = await client.graphql<GraphQLQuery<UpdateOffer>>({
                                                                             query: (updateOffer),
                                                                             variables: {input: record},
                                                                         });

        return response.data?.updateOffer;
    } catch (e) {
        console.trace(e);
    }
};

export const deleteRecord = async (id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<DeleteOffer>>({
                                                                             query: (deleteOffer),
                                                                             variables: {input: {id: id}},

                                                                         });
        return response.data?.deleteOffer;
    } catch (e) {
        console.trace(e);
    }
};
