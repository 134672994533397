import {
    getMeteringRecord,
    listMeteringRecords
} from './queries';
import {
    createMeteringRecord,
    deleteMeteringRecord,
    updateMeteringRecord,
} from './mutations';

import {GraphQLQuery,} from '@aws-amplify/api';
import {MeteringRecord} from "../types.ts";
import {V6Client} from "@aws-amplify/api-graphql";
import {generateClient} from "aws-amplify/api";
import {showErrorNotification} from "../../../components/ServiceNotifications.tsx";


export type ListMeteringRecords = {
    listMeteringRecords: { items: MeteringRecord[] }
}

export type GetMeteringRecord = {
    getMeteringRecord: MeteringRecord
}

export type CreateMeteringRecord = {
    createMeteringRecord: MeteringRecord
}

export type UpdateMeteringRecord = {
    updateMeteringRecord: MeteringRecord
}

export type DeleteMeteringRecord = {
    deleteMeteringRecord: string
}


let client: V6Client
try {
    client = generateClient();
} catch (e: any) {
    showErrorNotification(e)
}


export const fetchRecords = async (filter: object) => {
    try {
        const response = await client.graphql<GraphQLQuery<ListMeteringRecords>>({
                                                                                     query: listMeteringRecords,
                                                                                     variables: {
                                                                                         filter: filter
                                                                                     },
                                                                                 });
        return response.data?.listMeteringRecords.items;
    } catch (e) {
        console.trace(e);
    }
};

export const fetch = async (id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<GetMeteringRecord>>({
                                                                                   query: getMeteringRecord,
                                                                                   variables: {id: id},
                                                                               });
        return response.data?.getMeteringRecord;
    } catch (e) {
        console.trace(e);
    }
};

export const add = async (payload: object) => {

    try {
        const response = await client.graphql<GraphQLQuery<CreateMeteringRecord>>({
                                                                                      query: createMeteringRecord,
                                                                                      variables: {input: payload},
                                                                                  });
        console.debug('in the service response', response);

        return response.data?.createMeteringRecord;
    } catch (e) {
        console.trace(e);
    }
};

export const update = async (id: string, record: MeteringRecord) => {

    console.debug('IN UPDATE', id, record);

    delete record.created;
    delete record.updated;

    try {
        const response = await client.graphql<GraphQLQuery<UpdateMeteringRecord>>({
                                                                                      query: (updateMeteringRecord),
                                                                                      variables: {input: record},
                                                                                  });

        return response.data?.updateMeteringRecord;
    } catch (e) {
        console.trace(e);
    }
};

export const deleteRecord = async (id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<DeleteMeteringRecord>>({
                                                                                      query: (deleteMeteringRecord),
                                                                                      variables: {input: {id: id}},

                                                                                  });
        return response.data?.deleteMeteringRecord;
    } catch (e) {
        console.trace(e);
    }
};
