export const listProducts = `
   query listProducts {
       listProducts {
           items {
              id
              productTitle
              shortDescription
              productId
              productArn
              productCode
              status
              meteringServiceSnsTopicArn
              created
              updated
           }
       }
   }
`;

export const getProduct = `
   query getProduct($id:ID!) {
       getProduct(id:$id) {
        id
        productTitle
        shortDescription
        productId
        productArn
        productCode
        status
        meteringServiceSnsTopicArn
        created
        updated
       } 
}`;
