import {useEffect} from 'react';

import {useParams} from 'react-router-dom';

import {Stack} from '@mantine/core';

import {RecordForm} from '../forms/RecordForm';

import {
    FormPageLayout,
    PlatformLoader,
    TabbedComponent,
} from '@flexinet/ui-components';
import {Profile} from '../components/Profile';

import {EditPageNavigation} from '../components/EditPageNavigation';
import {useProduct} from '../hooks/useProduct.ts';
import {useProductsSelection} from '../hooks/useProductsSelection.ts';
import {useEditPageTools} from '../hooks/useEditPageTools';
import {useProducts} from "../hooks/useProducts.ts";
import {IndexPage as NotificationSubscriptionsPage} from "../../notification-subscriptions/pages/IndexPage.tsx";
import {useAccessControl} from "../../../hooks/useAccessControl.ts";
import {useAuth} from "../../../hooks/useAuth.ts";

export const EditPage = () => {

    const {role} = useAuth();
    const {evaluateAction} = useAccessControl(role as string)


    const {id: productId} = useParams();

    if (typeof productId === 'undefined') {
        throw new Error('productId is required parameter');
    }

    const {fetchRecords} = useProducts();

    const {
        record: product,
        fetchRecord,
        updateRecord,
    } = useProduct(productId);

    const {handleClearSelection} = useProductsSelection();

    const {
        mode,
        tools,
    } = useEditPageTools();


    useEffect(() => {
        handleClearSelection();
    }, []);

    useEffect(() => {
        fetchRecords();
    }, []);

    useEffect(() => {
        fetchRecord();
    }, [productId]);

    if (!product) {
        return <PlatformLoader message='Loading product...Please wait'/>;
    }

    const tabs = []
    if (evaluateAction('ListNotificationSubscriptions')) {
        tabs.push({
                      title: 'Notification Subscriptions',
                      component: <NotificationSubscriptionsPage/>
                  })
    }

    return (
        <FormPageLayout
            title={product.name ? product.name : product.id}
            pageNavigation={<EditPageNavigation component={product}/>}
            tools={tools}
        >


            {mode === 'view' ?
                <Stack gap='lg'>
                    <Profile
                        record={product}
                    />

                    <TabbedComponent tabs={tabs}></TabbedComponent>

                </Stack>
                :
                <RecordForm
                    record={product}
                    handleSubmit={updateRecord}
                />}


        </FormPageLayout>
    );
};
