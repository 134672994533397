import {
    getRegistration,
    listRegistrations
} from './queries';
import {
    createRegistration,
    deleteRegistration,
    updateRegistration,
} from './mutations';

import {GraphQLQuery,} from '@aws-amplify/api';
import {Registration} from "../types.ts";
import {V6Client} from "@aws-amplify/api-graphql";
import {generateClient} from "aws-amplify/api";
import {showErrorNotification} from "../../../components/ServiceNotifications.tsx";


export type ListRegistrations = {
    listRegistrations: { items: Registration[] }
}

export type GetRegistration = {
    getRegistration: Registration
}

export type CreateRegistration = {
    createRegistration: Registration
}

export type UpdateRegistration = {
    updateRegistration: Registration
}

export type DeleteRegistration = {
    deleteRegistration: string
}


let client: V6Client
try {
    client = generateClient();
} catch (e: any) {
    showErrorNotification(e)
}


export const fetchRecords = async (filter: object) => {
    try {
        const response = await client.graphql<GraphQLQuery<ListRegistrations>>({
                                                                                   query: listRegistrations,
                                                                                   variables: {filter: filter},
                                                                               });
        return response.data?.listRegistrations.items;
    } catch (e) {
        console.trace(e);
    }
};

export const fetch = async (id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<GetRegistration>>({
                                                                                 query: getRegistration,
                                                                                 variables: {id: id},
                                                                             });
        return response.data?.getRegistration;
    } catch (e) {
        console.trace(e);
    }
};

export const add = async (payload: object) => {

    try {
        const response = await client.graphql<GraphQLQuery<CreateRegistration>>({
                                                                                    query: createRegistration,
                                                                                    variables: {input: payload},
                                                                                });
        console.debug('in the service response', response);

        return response.data?.createRegistration;
    } catch (e) {
        console.trace(e);
    }
};

export const update = async (id: string, record: Registration) => {

    console.debug('IN UPDATE', id, record);

    delete record.created;
    delete record.updated;

    try {
        const response = await client.graphql<GraphQLQuery<UpdateRegistration>>({
                                                                                    query: (updateRegistration),
                                                                                    variables: {input: record},
                                                                                });

        return response.data?.updateRegistration;
    } catch (e) {
        console.trace(e);
    }
};

export const deleteRecord = async (id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<DeleteRegistration>>({
                                                                                    query: (deleteRegistration),
                                                                                    variables: {input: {id: id}},

                                                                                });
        return response.data?.deleteRegistration;
    } catch (e) {
        console.trace(e);
    }
};
