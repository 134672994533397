import {I18n} from '@aws-amplify/core';

import {
    DeleteRecordsConfirmationButton,
    PlatformLoader,
    ReloadButton,
    SimplePageHeader,
} from '@flexinet/ui-components';

import {Box,} from '@mantine/core';

import {useParams} from 'react-router-dom';

import {useNotificationSubscriptions} from '../hooks/useNotificationSubscriptions.ts';

import {useAccessControl} from '../../../hooks/useAccessControl';
import PropTypes from 'prop-types';
import {useNotificationSubscriptionsSelection} from "../hooks/useNotificationSubscriptionsSelection.ts";
import {ModalContextProvider} from "../../../context/ModalContext.tsx";
import {RecordForm} from "../forms/RecordForm.tsx";
import {useNotificationSubscription} from "../hooks/useNotificationSubscription.ts";
import {useProduct} from "../../products/hooks/useProduct.ts";
import {useEffect} from "react";
import {useAuth} from "../../../hooks/useAuth.ts";

const ComponentsReloadButton = () => {
    const {id: productId} = useParams()

    if (!productId) {
        throw new Error('productId is required parameter')
    }
    const {fetchRecords} = useNotificationSubscriptions(productId);

    return (
        <Box mx='s'>
            <ReloadButton onClick={() => fetchRecords()}/>
        </Box>
    );
};

const AddRecordButton = (
    {
        buttonText = 'Add Notification Subscription',
        size = 'sm',
    }: {
        buttonText?: string,
        size?: string,
    }
) => {

    const {id: productId} = useParams();

    if (!productId) {
        throw new Error('productId is required parameter')
    }

    const {
        record: product,
        fetchRecord
    } = useProduct(productId)

    const {createRecord} = useNotificationSubscription();

    useEffect(() => {
        fetchRecord()
    }, [])

    if (!product) {
        return <PlatformLoader/>
    }

    return (
        <ModalContextProvider
            title='Notification Subscription'
            buttonOptions={{
                variant: 'filled',
                size: size,
                label: I18n.get(buttonText),
                iconType: 'add',
            }}
        >
            <RecordForm
                record={{
                    topicArn: product.meteringServiceSnsTopicArn as string,
                }}
                handleSubmit={createRecord}
            />
        </ModalContextProvider>
    );


};

const DeleteRecordButton = () => {
    const {id: productId} = useParams()

    if (!productId) {
        throw new Error('productId is required parameter')
    }

    const {deleteRecords} = useNotificationSubscriptions(productId);

    const {
        selection,
        handleClearSelection,
    } = useNotificationSubscriptionsSelection();

    const recordIds = Object.keys(selection);
    let disabled = true;
    if (recordIds.length > 0) {
        disabled = false;
    }


    return (<DeleteRecordsConfirmationButton
        disabled={disabled}
        recordIdsLength={recordIds.length}
        handleConfirm={() => {
            deleteRecords()
            handleClearSelection()
        }}/>);
};


const useTools = () => {

    const {role} = useAuth();
    const {evaluateAction} = useAccessControl(role as string)

    const tools = [<ComponentsReloadButton key='reload'/>];

    if (evaluateAction('DeleteNotificationSubscription')) {
        tools.push(<DeleteRecordButton key='delete'/>);
    }

    if (evaluateAction('CreateNotificationSubscription')) {
        tools.push(<AddRecordButton key='add'/>);
    }


    return tools;
};

export const IndexPageHeader = () => {
    return (
        <SimplePageHeader
            title={I18n.get('Notification Subscriptions')}
            tools={useTools()}
        />
    );
};

AddRecordButton.propTypes = {
    buttonText: PropTypes.string,
    size: PropTypes.string,
    onClick: PropTypes.func,
};