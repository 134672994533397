import {showNotification} from "@mantine/notifications";
import {
    AiOutlineExclamation,
    AiOutlineInfo
} from "react-icons/ai";


export const showErrorNotifications = (e: any) => {

    console.debug('handleAppSyncResponseException', e)

    if (typeof e.errors !== 'undefined' && e.errors.length > 0) {
        e.errors.forEach((error: { message: string; }) => {
            showErrorNotification(error.message);
        })
    }
}


export const showSuccessNotification = (message = 'Record saved successfully') => {
    showNotification({
                         title: 'Success',
                         icon: <AiOutlineInfo/>,
                         message: message,
                         color: 'teal',
                     })
}

export const showErrorNotification = (message = 'Error performing the operation') => {
    showNotification({
                         title: 'Error',
                         icon: <AiOutlineExclamation/>,
                         message: message,
                         color: 'red',
                     })

}

